import React, { useState, useEffect } from 'react'
import { BaseLayout } from '@layouts/base'
import { PageProps } from 'gatsby'
import axios, { AxiosResponse } from 'axios'

type CommercialLicenseTermsResponse = {
  termsHtml: string
}

const CommercialLicensePage: React.FC<PageProps> = () => {
  const [termsHtml, setTermsHtml] = useState('')

  useEffect(() => {
    const fetch = async () => {
      const result: AxiosResponse<CommercialLicenseTermsResponse> = await axios.get(
        '/api/commercial-license/terms'
      )
      setTermsHtml(result.data.termsHtml)
    }
    fetch()
  }, [])

  return (
    <BaseLayout title='Commercial License'>
      <section className='page-content'>
        <div className='page-content__container container'>
          <div
            className='text-content'
            style={{ minHeight: '100vh' }}
            dangerouslySetInnerHTML={{ __html: termsHtml }}
          />
        </div>
      </section>
    </BaseLayout>
  )
}

export default CommercialLicensePage
